<template>
  <v-app id="nuokka">
    <AppHeader />
    <SearchBar />
    <Login />
    <Navigation />
    <v-main ref="mainContent" :class="{'meta-bar': hasMessageMeta}">
      <v-container fluid>
        <v-row
          align="center"
          justify="center"
          no-gutters
        >
          <v-col
            cols="12"
            sm="8"
            md="6"
            lg="4"
          >
            <PleaseWait v-if="appLoading" />
            <keep-alive v-else>
              <router-view :key="$route.fullPath" />
            </keep-alive>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-snackbar
      v-model="snackbar"
      color="success"
      :timeout="snackbarTimeout"
    >
      {{ snackbarText }}
      <v-btn
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
    <NavigationBottom />
  </v-app>
</template>

<script>
  import { mapGetters } from 'vuex'
  import AppHeader from '@/components/app/AppHeader'
  import SearchBar from '@/components/app/SearchBar'
  import Login from '@/components/app/Login'
  import Navigation from '@/components/app/Navigation'
  import NavigationBottom from '@/components/app/NavigationBottom'
  import PleaseWait from '@/components/app/PleaseWait'

  export default {
    components: {
      AppHeader,
      SearchBar,
      Login,
      Navigation,
      NavigationBottom,
      PleaseWait
    },
    data: () => ({}),
    computed: {
      ...mapGetters([
        'appLoading',
        'snackbarText',
        'snackbarTimeout'
      ]),
      hasMessageMeta() { return Boolean(this.$route.query.sharePost || this.$route.query.shareProduct) },
      snackbar: {
        get: function() {
          return this.$store.getters.snackbar
        },
        set: function(newValue) {
          this.$store.dispatch('setSnackbar', newValue)
        }
      }
    }
  }
</script>
