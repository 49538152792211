const sanitizeTag = tag => {
  return tag
    .toString()
    .toLowerCase()
    .replace(/[\u200B-\u200D\uFEFF]+/g, '')  // Zero-width chars
    .replace(/\s+/g, ' ')
    .replace(/[_\u002D\u058A\u05BE\u1400\u1806\u2010-\u2015\u2E3A\u2E3B\u2E40\uFE58\uFE63\uFF0D]+/g, '-')
    .trim()
}

export default class TagList extends Array {
  constructor() {
    let items = null

    if (arguments.length) {
      const singleArgument = arguments.length === 1

      if (singleArgument) {
        if (Array.isArray(arguments[0])) {
          items = arguments[0]
        }
      } else {
        items = Array.from(arguments)
      }
    }

    if (items) {
      super(...[...new Set(items.map(sanitizeTag))])
    } else {
      super()
    }
  }

  push(value) {
    if (typeof value === "string") {
      value = sanitizeTag(value)
      if (this.includes(value) === false) {
        super.push(value)
      }
    }
  }
}
