import TagList from '@/aggroot/taglist'
import BaseAggRoot from '@/aggroot/base'
import { accountStatus } from '@/enums'

export default class Account extends BaseAggRoot {
  static _atts = [
    "id", "account", "created", "updated",
    "legalName", "taxRegion", "currency", "industry", "email",
    "about", "tags", "type", "status", "media", "stripeUserID",
    "collection", "shipping", "intShipping", "shippingOption", "shippingRates"
  ]

  constructor() {
    super(...arguments)

    Array
      .from(arguments)
      .forEach((name, index) => {
        if (index > BaseAggRoot._atts.length - 1) {
          this[Account._atts[index]] = name
        }
      })

    this.account =
      this.account &&
      this.account
        .toString()
        .toLowerCase()
        .replace(/[^a-z0-9-]/ig, '')
    this.taxRegion = this._uppercase(this.taxRegion)
    this.currency = this._uppercase(this.currency)
    this.shippingRates =
      !Array.isArray(this.shippingRates) ?
        [] : this.shippingRates
    this.type = this.type || 'buyer'

    this.status =
      Object
        .values(accountStatus)
        .includes(this.status) ?
        this.status : accountStatus.ACTIVE
  }

  getShippingRatesByCurrency(currency) {
    return this.shippingRates.filter(rate => this.shipping ?
      rate.currency === currency && rate.type === this.shipping :
      rate.currency === currency
    )
  }

  get isStripeConnected() {
    return Boolean(this.stripeUserID)
  }

  get tags() {
    return this._tags
  }

  set tags(value) {
    this._tags = value instanceof TagList ? value : new TagList(value)
  }

  toObj() {
    const obj = {...super.toObj()}

    if (obj.shippingRates) {
      obj.shippingRates.forEach(rate => {
        rate.minimum =
          rate.minimum !== undefined &&
          rate.minimum !== null &&
          Number.parseFloat(rate.minimum).toFixed(2)
        rate.maximum =
          rate.maximum !== undefined &&
          rate.maximum !== null &&
          Number.parseFloat(rate.maximum).toFixed(2)
        rate.price =
          rate.price !== undefined &&
          rate.price !== null &&
          Number.parseFloat(rate.price).toFixed(2)
      })
    }

    obj.tags = this.tags
    delete obj._tags

    return obj
  }

  toOrderObj(currency) {
    return {
      currency,
      account: this.account,
      legalName: this.legalName,
      taxRegion: this.taxRegion,
      email: this.email,
      stripeUserID: this.stripeUserID,
      collection: this.collection,
      shipping: this.shipping,
      intShipping: this.intShipping,
      shippingOption: 0,
      shippingRates: this.getShippingRatesByCurrency(currency),
      shippingCost: null,
      merchantOrderTotal: null
    }
  }

  static fromObj(obj) {
    return new Account(
      ...Account._atts.map(
        att => obj[att]
      )
    )
  }

  static fromJSON(json) {
    return Account.fromObj(
      JSON.parse(json)
    )
  }
}
