import TagList from '@/aggroot/taglist'
import BaseAggRoot from '@/aggroot/base'

export default class Product extends BaseAggRoot {
  static _atts = [
    "id", "account", "created", "updated",
    "userId", "hasMedia",
    "title", "description", "code", "unitSize", "stock",
    "shipping", "collection",
    "prices", "tags", "customOptions", "variants",
    "taxCode"
  ]

  constructor() {
    super(...arguments)

    Array
      .from(arguments)
      .forEach((name, index) => {
        if (index > BaseAggRoot._atts.length - 1) {
          this[Product._atts[index]] = name
        }
      })

    this.prices = !Array.isArray(this.prices) ? [] : this.prices
    this.customOptions = !Array.isArray(this.customOptions) ? [] : this.customOptions
    this.variants = !Array.isArray(this.variants) ? [] : this.variants

    this.taxCode = this.taxCode || 'fully-taxable-product'
  }

  get csv() {
    return this.getCSVRow()
  }

  get hasVariants() {
    return Boolean(this.customOptions && this.customOptions.length)
  }

  get tags() {
    return this._tags
  }

  set tags(value) {
    this._tags = value instanceof TagList ? value : new TagList(value)
  }

  isCollectionAvailable(account) {
    return account && account.collection && Boolean(
      account.collection === 'available-all' || (
        account.collection === 'available-some' && this.collection
      )
    )
  }

  getProductShippingRate(currency, merchant) {
    return merchant.shipping === 'product' ?
      this.findPrice(currency, this.prices).shipping : false
  }

  findPrice(currency, prices) {
    return prices && prices.length ?
      prices.find(p => p.currency === currency) ||
      prices.find(p => p.currency === 'USD') ||
      prices[0] :
      undefined
  }

  derivePrice(currency, variantSearch) {
    const variant = variantSearch && this.getVariant(variantSearch)
    const variantPrice = variant && this.findPrice(currency, variant.prices)
    return variantPrice || this.findPrice(currency, this.prices)
  }

  deriveStock(variantSearch) {
    const masterStock = this.stock || 0
    // const totalVariantStock = this.variants && this.variants.length ?
    //   this.variants.reduce((acc, variant) => acc += variant.stock || 0, 0) : 0
    const variant = variantSearch && this.getVariant(variantSearch)
    const variantStock = (variant && variant.stock) || 0
    return Number.parseFloat(variant ? variantStock : masterStock)
  }

  deriveSKU(variantSearch) {
    const variant = variantSearch && this.getVariant(variantSearch)
    const variantCode = variant && variant.code
    return variant ? variantCode : this.code
  }

  getVariant(search) {
    return (
      search.constructor === Object &&
      Object.keys(search).length > 0 &&
      this.variants.find(
        findVariant => Object.entries(search).every(
          ([key, value]) => findVariant[key.toLowerCase()] === value
        )
      )
    )
  }

  getVariantByIndex(index) {
    return this.variants[index]
  }

  getVariantIndex(search) {
    return this.variants.findIndex(
      findVariant => Object.entries(search).every(
        ([key, value]) => findVariant[key.toLowerCase()] === value
      )
    )
  }

  getCSVRow() {
    return {
      code: this.code,
      title: this.title,
      tags: this.tags.join(','),
      collection: String(this.collection).toLowerCase(),
      shipping: String(this.shipping).toLowerCase(),
      stock: this.stock,
      "unit-size": this.unitSize,
      "tax-category": this.taxCode,
      prices: this.prices.map(pr => pr.currency + ':' + pr.unitPrice + ':' + pr.shipping).join(','),
      options: this.customOptions.map(o => o.description + ':' + o.options.join(',')).join(';')
    }
  }

  getOptionSearch(variant) {
    return this.customOptions.reduce((acc, opt) => {
      const label = opt.description.toLowerCase()
      acc[label] = variant[label]
      return acc
    }, {})
  }

  updateVariant(variant) {
    variant.prices = variant.prices.map(price => ({
      ...price,
      unitPrice: Number.parseFloat(price.unitPrice).toFixed(2),
      shipping: Number.parseFloat(price.shipping).toFixed(2)
    }))

    if (!this.variants) {
      this.variants = [variant]
    } else {
      const search = this.getOptionSearch(variant)
      const existing = this.getVariantIndex(search)

      if (existing > -1) {
        this.variants.splice(existing, 1, variant)
      } else {
        this.variants.push(variant)
      }

      const totalVariantStock = this.variants.reduce(
        (acc, variant) =>
          acc + (variant.stock ?
            Number.parseFloat(variant.stock) : 0),
        0
      )

      if (totalVariantStock > this.stock) {
        this.stock = totalVariantStock
      }
    }

    return this
  }

  deleteVariant(variant) {
    if (this.variants) {
      const search = this.getOptionSearch(variant)
      const existing = this.getVariantIndex(search)

      if (existing > -1) {
        this.variants.splice(existing, 1)
      }
    }

    return this
  }

  static parsePrices(prices) {
    return prices && prices.length ? prices.map(price => ({
      ...price,
      unitPrice: (
        Number.isNaN(Number.parseFloat(price.unitPrice)) ?
          0 : Number.parseFloat(price.unitPrice)
      ).toFixed(2),
      shipping: (
        Number.isNaN(Number.parseFloat(price.shipping)) ?
          0 : Number.parseFloat(price.shipping)
      ).toFixed(2)
    })) : []
  }

  toObj() {
    const obj = {...super.toObj()}

    obj.stock = Number.parseFloat(obj.stock)
    obj.prices = Product.parsePrices(obj.prices)

    obj.variants = obj.variants.map(variant => ({
      ...variant,
      stock: Number.parseFloat(variant.stock),
      prices: Product.parsePrices(variant.prices)
    }))

    obj.taxCode = obj.taxCode || 'fully-taxable-product'

    obj.tags = this.tags
    delete obj._tags

    return obj
  }

  static fromObj(obj) {
    return new Product(
      ...Product._atts.map(
        att => obj[att]
      )
    )
  }

  static fromJSON(json) {
    return Product.fromObj(
      JSON.parse(json)
    )
  }
}
