export default class BaseAggRoot {
  static _atts = ["id", "account", "created", "updated"]

  constructor(id, account, created, updated) {
    this.id = id
    this.account = account
    this.created = created
    this.updated = updated

    const now = new Date().getTime()

    if (this.created === undefined) {
      this.created = now
    }

    if (this.updated === undefined) {
      this.updated = now
    }
  }

  _uppercase(value) {
    return typeof value === 'string' ? value.toUpperCase() : value
  }

  _lowercase(value) {
    return typeof value === 'string' ? value.toLowerCase() : value
  }

  toObj() {
    let obj = {
      ...this,
      updated: new Date().getTime()
    }

    Object.entries(obj).forEach(([key, value]) => {
      if (value === undefined) {
        delete obj[key]
      }
    })

    return obj
  }

  toJSON() {
    return JSON.stringify(this.toObj())
  }
}
