import BaseAggRoot from '@/aggroot/base'

export default class MerchantOrderItem extends BaseAggRoot {
  static _atts = [
    "id", "account", "created", "updated",
    'productID', 'cartIndex',
    'code', 'title', 'hasMedia', 'mediaSrc', 'stock',
    'quantity', 'options', // order important here for the setter
    'currency', 'unitPrice', 'unitSize',
    'isInternational', 'shippingRate', 'shippingOption',
    'isOrderable'
  ]

  constructor() {
    super(...arguments)

    Array
      .from(arguments)
      .forEach((name, index) => {
        if (index > BaseAggRoot._atts.length - 1) {
          this[MerchantOrderItem._atts[index]] = name
        }
      })

    this.quantity = Number.parseFloat(this.quantity)
    this.unitPrice = Number.parseFloat(this.unitPrice)
  }

  formatPrice(price) {
    return Number.isNaN(price) ?
      'Error' :
      new Intl.NumberFormat(
        navigator.language || 'en', {
          style: 'currency',
          currency: this.currency
        }).format(price)
  }

  get options() {
    return this._options
  }

  set options(options) {
    const temp = { ...options }
    if ('quantity' in temp) {
      this.quantity = Number.parseFloat(temp.quantity)
      delete temp.quantity
    }
    this._options = temp
  }

  get isInternational() {
    return this._isInternational
  }

  set isInternational(value) {
    this._isInternational = Boolean(value)
  }

  get itemTotal() {
    return Number(
      this.quantity * this.unitPrice
    )
  }

  get shippingCost() {
    return this.shippingOption && this.shippingOption.startsWith('collection') ?
      0 : this.shippingRate !== false && (Number.parseFloat(this.shippingRate) * this.quantity)
  }

  get lineTotal() {
    return this.itemTotal + this.shippingCost
  }

  toOrderObj() {
    return {
      id: this.productID,
      code: this.code,
      title: this.title,
      options: this.options,
      currency: this.currency,
      unitPrice: this.unitPrice,
      unitSize: this.unitSize,
      quantity: this.quantity,
      itemTotal: this.itemTotal,
      isInternational: this.isInternational,
      shippingOption: this.shippingOption,
      shippingCost: this.shippingCost,
      lineTotal: this.lineTotal,
      isOrderable: this.isOrderable
    }
  }

  static fromObj(obj) {
    return new MerchantOrderItem(
      ...MerchantOrderItem._atts.map(
        att => obj[att]
      )
    )
  }

  static fromJSON(json) {
    return MerchantOrderItem.fromObj(
      JSON.parse(json)
    )
  }
}
